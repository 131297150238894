import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import Contact from '../components/contact';
import SEO from "../components/seo";

const EventsPage = ({ data }) => (
  <Layout>
    <SEO title="最新動態" />
    <Header />
    <Contact />

    <section>
      <div className="video-banner">
        <video 
          autoPlay
          muted
          loop
          style={{ height:"100vh", width:"100%", objectFit:"cover", position:"absolute" }}
        >
          <source src="/static/videos/events_background_izj0za.mp4" type="video/mp4"></source>
        </video>
        <div className="banner-second-main" >
          <h1 className="main-banner-title">發掘<br />探索<br />觸動靈感</h1>
        </div>
      </div>
    </section>

    <div className="page-content">
      <section className="section">
        <div className="container w-container">
        <h3 className="product-image-title" style={{ color:"#232323"}}>最新活動</h3>
        {data.allStrapiEvent.edges.map(document => (
          <div key={document.node.id}>
            {(document.node.featured && (document.node.language === "English" || document.node.language === "Both") ) ?
            <div className="w-col">
              <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <div className="event-wrapper" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <div className="product-photo" style={{ maxWidth: "500px", margin: "0 auto" }}>
                    {document.node.event_cover ? <Img fluid={document.node.event_cover.childImageSharp.fluid} /> : <span />}
                  </div>
                  <div className="team-content">
                    <h4 className="event-office-title">{document.node.name}</h4>
                    <p className="event-office-text">{document.node.description}</p>
                    <p className="event-office-text">{moment(document.node.startdate).utc().format('YYYY/MM/DD')} to {moment(document.node.enddate).utc().format('YYYY/MM/DD')}</p>
                    <p className="event-office-text">{document.node.time}</p>
                    <p className="event-office-text">{document.node.place} </p>
                    <p className="event-office-text">Booth: {document.node.booth_number}</p>
                  </div>
                </div>
              </ScrollAnimation>
            </div> : <span />
          }
          </div>
        ))}
        </div>
      </section>
      <section className="sub-section">
        <div className="container w-container">
        {data.allStrapiEvent.edges.map(document => (
          <div key={document.node.id}>
            {(document.node.language === "English" || document.node.language === "Both" ) ?
            <div className="w-col w-col-4 w-col-medium-6">
              <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <div className="event-wrapper" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <div className="product-photo">
                    {document.node.event_cover ? <Img fluid={document.node.event_cover.childImageSharp.fluid} /> : <span />}
                  </div>
                  <div className="team-content">
                    <h4 className="event-office-title">{document.node.name}</h4>
                    <p className="event-office-text">{document.node.description}</p>
                    <p className="event-office-text">{moment(document.node.startdate).utc().format('YYYY/MM/DD')} to {moment(document.node.enddate).utc().format('YYYY/MM/DD')}</p>
                    <p className="event-office-text">{document.node.time}</p>
                    <p className="event-office-text">{document.node.place} </p>
                    <p className="event-office-text">Booth: {document.node.booth_number}</p>
                  </div>
                </div>
              </ScrollAnimation>
            </div> : <span />
          }
          </div>
        ))}
        </div>
      </section>
    </div>
    <Footer />
  </Layout>
)
export default EventsPage;

export const pageQuery = graphql`  
  query EventsQuery {
    allStrapiEvent(sort: {order: DESC, fields: startdate}) {
      edges {
        node {
          id
          event_cover {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          language
          name
          place
          startdate
          enddate
          booth_number
          featured
        }
      }
    }
  }
`
